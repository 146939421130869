'use client';

import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
import { LAYOUT_VERSION } from 'src/config-global';
import Stack from '@mui/material/Stack';
import { FONT_PRIMARY } from '../../theme/landing/typography';


// ----------------------------------------------------------------------

function getLogoMapping() {
  return {
    tracklution: TracklutionLogo,
    leadspark: LeadSparkLogo,
    lazzaweb: LazzawebLogo,
    // Add logos here
  };
}

// ----------------------------------------------------------------------

function LogoComponent(props, ref) {
  const { disabledLink = false, isSimple = false, onDark = false, width = 180, sx, centered = false, ...other } = props;
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const SelectedLogo = getLogoMapping()[LAYOUT_VERSION] || TracklutionLogo;

  const logoComponent = (
    <SelectedLogo
      width={width}
      sx={sx}
      disabledLink={disabledLink}
      isSimple={isSimple}
      onDark={onDark}
      isLight={isLight}
      theme={theme}
      centered={centered}
      {...other}
    />
  );

  if (disabledLink) {
    return logoComponent;
  }

  return (
    <Link component={RouterLink} href='/' sx={{ display: 'contents' }}>
      {logoComponent}
    </Link>
  );
}

const Logo = forwardRef(LogoComponent);
LogoComponent.propTypes = {
  disabledLink: PropTypes.bool,
  isSimple: PropTypes.bool,
  onDark: PropTypes.bool,
  sx: PropTypes.object,
  width: PropTypes.number,
  centered: PropTypes.bool,
};
export default Logo;

// ----------------------------------------------------------------------

LazzawebLogo.propTypes = LogoComponent.propTypes;

function LazzawebLogo({ width, sx, isSimple, isLight, onDark, disabledLink, theme, centered }) {
  return (
    <Box sx={{ pt: 0.5, pr: !centered && 1, ...sx, width: width + 20, textAlign: centered && 'center' }}>
      <Box
        component='img'
        src={isLight ? '/assets/cobranding/lazzaweb/lazzaweb-logo-light.svg' : '/assets/cobranding/lazzaweb/lazzaweb-logo-dark.svg'}
        sx={{ width, pt: 1, cursor: !disabledLink && 'pointer' }}
      />
      <Stack direction='row' spacing={0.625} alignItems='center'
             sx={{ zoom: { xs: 0.85, sm: 0.9 }, fontFamily: FONT_PRIMARY.style.fontFamily, height: 34, lineHeight: 1, justifyContent: centered && 'center' }}>
        <Stack direction='row' alignItems='center' sx={{
          height: 18,
          fontSize: '12.5px',
          textTransform: 'uppercase',
          color: 'text.primary',
          fontWeight: 600,
          whiteSpace: 'nowrap',
          lineHeight: 0,
        }}>
          Powered By
        </Stack>
        <Stack direction='row' alignItems='center' sx={{ pb: 0.24, pr: 0.5 }}>
          <TracklutionLogo width={90} sx={{ maxWidth: '100%', pt: 0, pb: 0, height: 18 }} isSimple={isSimple} isLight={isLight} onDark={onDark}
                           disabledLink={disabledLink}
                           theme={theme} /></Stack>
      </Stack>
    </Box>
  );
}


LeadSparkLogo.propTypes = LogoComponent.propTypes;

function LeadSparkLogo({ width, sx, isSimple, isLight, onDark, disabledLink, theme }) {
  return (
    <Box
      component='img'
      src={isLight ? '/assets/images/leadspark-logo-violetti.png' : '/assets/images/leadspark-logo-valkoinen.png'}
      sx={{ width, pt: 0.75, pb: 2, cursor: !disabledLink && 'pointer', ...sx }}
    />
  );
}


TracklutionLogo.propTypes = LogoComponent.propTypes;

function TracklutionLogo({ width, sx, isSimple, isLight, onDark, disabledLink, theme }) {
  const PRIMARY_LIGHT = theme.palette.common.white;
  const PRIMARY_MAIN = '#FA541C';
  const PRIMARY_DARK = theme.palette.grey[800];

  return (
    <Box
      sx={{ width, pt: 0.75, pb: 1, cursor: !disabledLink && 'pointer', ...sx }}
    >
      {
        isSimple ? (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='100%'
            height='100%'
            fill='none'
            viewBox='0 0 512 512'
          >
            <ellipse cx='405.143' cy='338.571' fill={PRIMARY_MAIN} rx='82.857' ry='82.857' />
            <path
              fill={isLight && !onDark ? PRIMARY_DARK : PRIMARY_LIGHT}
              d='M114.742 355.332H256v66.097H24v-61.376l140.323-203.956H24V90h232v61.376L114.742 355.332z'
            />
          </svg>
        ) : (
          <svg version='1.0' xmlns='http://www.w3.org/2000/svg'
               width='100%'
               height='100%' viewBox='0 0 1037 211'
          >
            <g transform='translate(0.000000,211.000000) scale(0.100000,-0.100000)'
               fill={isLight && !onDark ? PRIMARY_DARK : PRIMARY_LIGHT} stroke='none'>
              <path d='M4790 1290 c0 -446 -4 -810 -9 -810 -13 0 -533 530 -529 540 2 5 104
112 226 239 l224 231 -179 0 -178 0 -174 -197 c-96 -109 -178 -199 -183 -201
-4 -2 -8 223 -8 500 l0 503 -140 -140 -140 -140 0 -672 0 -673 140 0 140 0 0
220 c0 121 4 220 9 220 4 0 101 -99 215 -220 l208 -220 329 0 329 0 0 815 0
815 -140 0 -140 0 0 -810z' />
              <path d='M7193 1960 c-56 -29 -83 -73 -83 -137 0 -98 69 -158 180 -158 115 1
175 55 175 157 0 61 -17 94 -68 132 -42 31 -149 34 -204 6z' />
              <path d='M363 1678 c-114 -115 -143 -149 -143 -171 l0 -27 -110 0 -110 0 0
-100 0 -100 110 0 110 0 0 -405 0 -405 145 0 145 0 0 405 0 404 143 3 142 3 3
97 3 97 -143 3 -143 3 -5 168 -5 167 -142 -142z' />
              <path d='M6663 1678 c-114 -115 -143 -149 -143 -171 l0 -27 -110 0 -110 0 0
-100 0 -100 110 0 110 0 0 -405 0 -405 145 0 145 0 0 405 0 404 143 3 142 3 3
97 3 97 -143 3 -143 3 -5 168 -5 167 -142 -142z' />
              <path d='M8250 1694 c-56 -11 -159 -48 -220 -80 -170 -88 -301 -249 -361 -444
-34 -108 -34 -302 0 -410 81 -262 275 -445 540 -511 111 -28 290 -23 396 11
39 12 74 25 78 30 5 4 -15 53 -43 110 -41 82 -54 100 -68 95 -44 -17 -142 -35
-192 -35 -215 0 -431 165 -486 374 -20 76 -18 202 6 278 90 293 430 440 705
304 131 -65 224 -172 265 -304 26 -84 27 -209 1 -290 l-18 -60 104 -48 c62
-28 107 -44 112 -38 33 35 65 222 58 333 -20 310 -214 558 -517 661 -51 18
-93 23 -200 25 -74 2 -146 1 -160 -1z' />
              <path d='M1955 1514 c-123 -12 -345 -66 -345 -84 0 -4 18 -46 40 -92 l40 -86
42 15 c122 42 303 55 394 28 29 -8 62 -28 79 -45 27 -28 30 -39 33 -107 4 -70
3 -76 -14 -70 -145 46 -162 50 -279 50 -215 1 -363 -61 -428 -182 -95 -174 9
-386 227 -461 190 -65 532 -38 717 56 l50 25 -3 347 -3 347 -33 67 c-62 127
-171 180 -392 191 -47 3 -103 3 -125 1z m127 -575 c29 -6 77 -23 106 -37 l52
-25 0 -103 0 -103 -57 -18 c-39 -12 -92 -17 -158 -17 -84 -1 -107 3 -141 21
-107 57 -136 166 -62 235 55 51 154 69 260 47z' />
              <path d='M3114 1509 c-89 -10 -217 -56 -286 -103 -281 -186 -319 -557 -82
-791 133 -132 328 -191 539 -165 139 17 244 53 327 111 l27 19 -44 83 c-24 45
-48 82 -52 82 -4 0 -35 -15 -69 -32 -288 -150 -584 -15 -584 266 0 91 22 151
80 214 112 122 284 149 453 69 34 -16 66 -30 71 -33 7 -3 106 162 106 176 0 1
-28 16 -62 33 -126 62 -284 89 -424 71z' />
              <path d='M9904 1510 c-76 -11 -144 -36 -217 -80 -36 -22 -68 -40 -71 -40 -4 0
-6 23 -6 50 l0 50 -140 0 -140 0 0 -510 0 -510 140 0 140 0 0 351 0 351 31 29
c87 81 240 113 329 67 34 -18 51 -35 70 -73 25 -50 25 -51 28 -387 l3 -338
145 0 145 0 -3 398 c-3 375 -5 400 -24 448 -12 29 -34 68 -49 88 -38 50 -131
94 -225 106 -84 10 -85 10 -156 0z' />
              <path d='M1283 1489 c-40 -19 -109 -86 -145 -139 -17 -24 -17 -23 -17 58 l-1
82 -140 0 -140 0 0 -510 0 -510 139 0 140 0 3 313 3 313 30 59 c42 83 97 120
173 119 35 0 76 -9 106 -23 27 -11 50 -20 51 -19 1 2 23 54 48 117 53 127 54
121 -27 146 -73 22 -172 20 -223 -6z' />
              <path d='M5232 1112 l3 -377 33 -67 c76 -154 229 -222 497 -221 199 1 337 32
448 103 l47 30 0 455 0 455 -145 0 -145 0 0 -399 0 -399 -42 -17 c-63 -26
-203 -32 -266 -11 -60 20 -116 76 -131 133 -7 23 -11 165 -11 366 l0 327 -145
0 -146 0 3 -378z' />
              <path d='M7140 980 l0 -510 145 0 145 0 0 510 0 510 -145 0 -145 0 0 -510z' />
              <path d='M8280 1163 c-152 -80 -161 -298 -16 -387 152 -95 346 11 346 189 -1
124 -88 215 -214 223 -52 3 -69 0 -116 -25z' />
              <path d='M8612 718 c40 -81 248 -523 291 -615 23 -51 45 -92 50 -93 4 0 7 12
8 28 0 15 15 88 32 162 l32 134 165 37 165 36 -55 25 c-30 14 -201 93 -379
177 -178 83 -325 151 -326 151 -2 0 6 -19 17 -42z' />
              <ellipse cx='8387' cy='965' fill={isLight && !onDark ? PRIMARY_MAIN : PRIMARY_MAIN} rx='250' ry='250' />
            </g>
          </svg>
        )
      }
    </Box>);
}
