'use client';

// @mui
import { Container, Typography } from '@mui/material';
// components
import Logo from 'src/components/logo';
import Stack from '@mui/material/Stack';

// ----------------------------------------------------------------------

export default function FooterSimple() {
  return (
    <Container sx={{ py: 8 }}>
      <Stack alignItems='center'>
        <Logo sx={{ mb: 3, width: 250, maxWidth: '50%' }} />
        <Typography variant='body1'>
          Contact: anni@tracklution.com
        </Typography>
        <Typography variant='body3' sx={{ color: 'text.secondary' }}>
          © Tracklution Oy. {new Date().getFullYear()}. All rights reserved.
        </Typography>
      </Stack>
    </Container>
  );
}
